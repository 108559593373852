import AsyncAlert from "./Components/AsyncAlert/AsyncAlert.jsx";
import { useState, useEffect, useRef } from "react";
import Backend from "../utils/api.js";
import MaterialTable from "material-table";
import { Select, MenuItem } from "@material-ui/core";
import "../utils/default.css";
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton,
} from "@chakra-ui/react";

export default function BadgeMachineTable({ newProps, id, user }) {
  const [machineTypeOptions, setMachineTypeOptions] = useState([]);
  const [alertMessage, setAlertMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [dataChangeCounter, setDataChangeCounter] = useState(0);
  const clearError = () => {
    setAlertMessage("")
    setSuccessMessage("")
  };
  const alertRef = useRef(null);
  const [expandedRow, setExpandedRow] = useState({});
  const [data, setData] = useState([]);

  useEffect(() =>{
    Backend.getFullBadgeInfo(user.UID)
   .then(({items, status}) => {
       if (status === 200){  
          setData(items);
       } else {
          setAlertMessage("server call failed!");
       }
    });
 }, [dataChangeCounter]);

 const handleDataChange = () => {
    setDataChangeCounter(prev => prev + 1);
  };

  const toggleTextDisplay = (rowKey) => {
    setExpandedRow(prev => ({
      ...prev,
      [rowKey]: !prev[rowKey] // Toggle display state for the row
    }));
  };

  function useOutsideAlerter(ref, onOutsideClick) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          onOutsideClick();
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, onOutsideClick]);
  }

  useOutsideAlerter(alertRef, clearError);

  useEffect(() => {
    Backend.getMachineTypes(user.UID, 100000).then((response) => {
      const options = response.items.map((machineType) => ({
        value: machineType.id,
        label: machineType.name,
      }));
      setMachineTypeOptions(options);
    });
  }, []);

  return (
    <div>
      <div className="dataTableWrapper">
        <MaterialTable
          title="Badge-machine-type relationships"
          columns={[
            { title: "Badge ID", field: "badgeclass_entity_id" },
            {
              title: "Machine Type Name",
              field: "name",
              editComponent: (props) => (
                <Select
                  value={props.value || ""}
                  onChange={(e) => props.onChange(e.target.value)}
                  fullWidth
                >
                  {machineTypeOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              ),
              render: (rowData) => {
                const machineType = machineTypeOptions.find(opt => opt.value === rowData.name);
                return machineType ? machineType.label : rowData.name;
              }
            },
            { title: "Badge Name", field: "badge_name", editable: "never" },
            { title: "Description", field: "description", editable: "never", 
            cellStyle: {
              minWidth: '500px',  // minimum width for the cell
            },
            render: rowData => {
              const isExpanded = expandedRow[rowData.tableData.id]; 
              const text = rowData.description;
              const shouldTruncate = text.length > 50 && !isExpanded; 

              return (
                <div onClick={() => toggleTextDisplay(rowData.tableData.id)} style={{ cursor: 'pointer' }}>
                  {shouldTruncate ? `${text.slice(0, 50)}...` : text}
                </div>
              );
            }},
          ]}
          data={data}
          editable={{
            onRowAdd: async (newData) => {
              try {
                const response = await Backend.newBadgeMachineTypeRelationship(
                  user.UID,
                  newData.badgeclass_entity_id,
                  newData.name,
                  id
                );

                if (response && response.error) {
                  setAlertMessage(response.error);
                }
                else {
                  setSuccessMessage("Row added successfully.");
                  handleDataChange();
                }
              } catch (error) {
                setAlertMessage("An unexpected error occurred.");
              }
            },
            ...(user.isSuperAdmin && {
              onRowUpdate: async (newData, oldData) => {
                try {
                  const response =
                    await Backend.updateBadgeMachineTypeRelationship(
                      user.UID,
                      oldData.badgeclass_entity_id,
                      newData.badgeclass_entity_id,
                      oldData.name,
                      newData.name,
                      id
                    );

                  if (response && response.error) {
                    setAlertMessage(response.error);
                  }
                  else {
                    setSuccessMessage("Row updated successfully.");
                    handleDataChange();
                  }
                } catch (error) {
                  setAlertMessage("An unexpected error occurred.");
                }
              },
              onRowDelete: (oldData) =>
                new Promise((resolve, reject) => {
                  Backend.deleteBadgeMachineTypeRelationship(
                    user.UID,
                    oldData.badgeclass_entity_id,
                    oldData.name,
                    id
                  )
                  .then((response) => {
                    if (response && response.error) {
                      setAlertMessage(response.error);
                      reject();
                    } else {
                      setSuccessMessage("Row deleted successfully.");
                      handleDataChange();
                      resolve();
                    }
                  })
                  .catch(error => {
                    setAlertMessage("An unexpected error occurred.");
                    reject();
                  });
                }),
            }),
          }}
          options={{
            actionsColumnIndex: user.isSuperAdmin ? 0 : -1,
            padding: 'default',
            rowStyle: rowData => ({
                backgroundColor: rowData.tableData.id % 2 === 0 ? '#FFF' : '#EEE'
            }),
            detailPanel: [{
              tooltip: 'Show Description',
              render: rowData => {
                return (
                  <div style={{ padding: 10, fontStyle: 'italic' }}>
                    {rowData.description}
                  </div>
                );
              }
            }]
          }}
        />
      </div>
      <div>
        <div ref={alertRef}>
          {alertMessage && (
            <div className="async-alert-container">
              <Alert status="error" variant="left-accent" my="2vh">
                <AlertIcon />
                <AlertTitle>Error</AlertTitle>
                <AlertDescription>{alertMessage}</AlertDescription>
                <CloseButton
                  onClick={clearError}
                  position="absolute"
                  right="8px"
                  top="8px"
                />
              </Alert>
            </div>
          )}
        </div>
      </div>
      <div>
        <div ref={alertRef}>
          {successMessage && (
            <div className="async-alert-container">
              <Alert status="success" variant="left-accent" my="2vh">
                <AlertIcon />
                <AlertTitle>Success!</AlertTitle>
                <AlertDescription>{successMessage}</AlertDescription>
                <CloseButton
                  onClick={clearError}
                  position="absolute"
                  right="8px"
                  top="8px"
                />
              </Alert>
            </div>
          )}
        </div>
      </div>
      <AsyncAlert uid={user.UID}></AsyncAlert>
    </div>
  );
}
